import { useEffect, useRef, useState } from 'react';

interface ProgressBarProps {
  milliseconds: number;
  progressKey: number;
}

export default function ProgressBar({ milliseconds, progressKey }: ProgressBarProps) {
  const [progress, setProgress] = useState(0);
  const animationRef = useRef<number>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: We do want this to have a sense of the progress key
  useEffect(() => {
    setProgress(0);
    let startTime: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const newProgress = Math.min((elapsedTime / milliseconds) * 100, 100);

      setProgress(newProgress);

      if (newProgress < 100) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [milliseconds, progressKey]);

  return (
    <div className='z-50 h-[5px] w-full overflow-hidden bg-neutral-200'>
      <div className='h-full bg-hb-orange' style={{ width: `${progress}%` }} />
    </div>
  );
}
