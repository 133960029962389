import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactImage from '@/components/ReactImage';
import Button from '@/components/ui/buttons/Button';
import ProgressBar from '@/components/ui/ProgressBar.tsx';
import type { TransformedPost } from '@/utils/content/parsers/transformWPData';

interface HeroScrollerProps {
  posts: TransformedPost[];
  className?: string;
}

export default function HeroScroller({ posts, className }: HeroScrollerProps) {
  const [progressKey, setProgressKey] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleSlideChange = useCallback(() => {
    setProgressKey((prev) => prev + 1);
  }, []);

  return (
    <div className={clsx('relative z-0 w-full overflow-hidden', 'h-[550px]', 'lg:aspect-square lg:h-auto', className)}>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={isMobile ? false : { delay: 10000, disableOnInteraction: false }}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        className='h-full w-full'
      >
        {posts.map((post) => (
          <SwiperSlide key={post.slug}>
            <a href={`/cover-story/${post.slug}`} className='block h-full'>
              <div className='relative h-full'>
                <ReactImage
                  src={post.featuredImage.link}
                  alt={post.title || ''}
                  layout='constrained'
                  width={1800}
                  height={1200}
                  priority
                  className='absolute inset-0 h-full w-full object-cover'
                />
                <div className='absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 via-50% to-black/10 to-100%' />
                <div className='absolute bottom-4 left-4 right-4 p-4 text-white'>
                  <h2 className='mb-2 font-robotoCondensed text-3xl font-semibold uppercase leading-9'>{post.title}</h2>
                  <div className='mb-4 font-acuminPro text-base text-white line-clamp-2'>{post.excerpt}</div>
                  <Button intent='primary' size='default'>
                    Read More
                  </Button>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className='absolute right-4 top-4 z-10 flex gap-2'>
        <Button
          intent='navigationDark'
          size='navigation'
          className='!h-10 !w-10'
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ChevronLeftIcon className='h-6 w-6' />
        </Button>
        <Button
          intent='navigationDark'
          size='navigation'
          className='!h-10 !w-10'
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ChevronRightIcon className='h-6 w-6' />
        </Button>
      </div>

      {!isMobile && (
        <div className='absolute bottom-0 left-0 right-0 z-10 hidden lg:block'>
          <ProgressBar milliseconds={10000} progressKey={progressKey} />
        </div>
      )}
    </div>
  );
}
